import React from 'react';
import '../styles/HeroSection.css';

// Importing Images
import hero1 from '../asset/home1.jpg';
import hero2 from '../asset/home2.jpeg';
import hero3 from '../asset/home3.jpg';
import hero4 from '../asset/home4.jpeg';
import hero5 from '../asset/home5.jpg';
import hero6 from '../asset/home6.jpg';
import hero7 from '../asset/home7.jpg';
import hero8 from '../asset/home8.jpg';

const HeroSection = () => {
  // Buttons for scrolling
  const buttons = [
    { src: hero1, id: '5', name: 'Neon Lights' },
    { src: hero2, id: '6', name: 'Acrylic Frame' },
    { src: hero3, id: '5', name: 'Neon Grid' },
    { src: hero4, id: '1', name: 'Photo Frames' },
    { src: hero5, id: '2', name: 'Valentine’s Day Gifts' },
    { src: hero6, id: '6', name: 'New Year Gifts' },
    { src: hero7, id: '3', name: 'Birthday Gifts' },
    { src: hero8, id: '7', name: 'Christmas Gifts' }
  ];

  // Scroll to section
  const handleScroll = (id) => {
    window.location.hash = `#${id}`;
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="hero-section">
      {/* Heading */}
      <div className="img-frames">
        <h1>Our Products</h1>
      </div>

      {/* Image Grid */}
      <div className="image-grid">
        {buttons.map((button, index) => (
          <div key={index} className="grid-item" onClick={() => handleScroll(button.id)}>
            <img src={button.src} alt={button.name} className="grid-image" />
            <p className="grid-text">{button.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeroSection;
